
 .van-popup--center{
  width: 90%;
}
.van-cell{
  line-height:80px;
  padding: 0;
  
}


:deep(.van-field__control) {
    text-align: right;
}

